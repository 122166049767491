// Our main CSS
import '../css/app.css'

import 'lazysizes'

import Typewriter from 'typewriter-effect/dist/core';

new Typewriter('#typewriter', {
  strings: ['Benvenuti a', 'Bienvenue à', 'Bienvenidos a', 'Willkommen in', 'Bem-vindo à'],
  autoStart: true,
  cursor: '',
  loop: true,
});
